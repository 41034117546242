import styled from "styled-components";

const PageStyle = styled.nav`





  position: absolute;
  color: white;
  width:100%;
  top: 90px;
  left: 0;

      
#bordertoppa{
  position: fixed;
        border-top: 4px solid white ;
        width: 80%;
        left: 10%;
        border-radius: 30px;
        z-index: 1;
    }



`

export default PageStyle